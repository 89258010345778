import React from "react"
import Layout from "../components/layouts"

export default () => (
  <Layout>
    <div className="facebook">
  		<h1>Find us on Facebook</h1>

      <div class="pure-u-1-1 center">
        You can keep up to date with the very latest class news, photos and updates over on our Facebook page
      </div>
      <br /><br />
      <div class="pure-u-1-1 center">
        <iframe title="FMMAA on Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffourmarksmartialartsacademy%2F&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=602431919775263" width="340" height="500" style={{border: "none", overflow: "hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
  	</div>
  </Layout>
)
